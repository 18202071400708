export enum connectionState {
    new = 0,
    cancelled = 1,
    discarded = 2,
    broken = 3,
    connected = 9
}

export interface Data {
    user: User,
    connections: Connection[],
    users: { [index: number]: User },
    interests: { [index: number]: string },
    development: boolean
}
export interface User { id: number, name: string, bio: string, emails: string[], interests: number[], seen_message_id: number, seen_connection_id: number }
export interface Connection { from_user_id: number, to_user_id: number, from_timestamp: number, to_timestamp: number, state: number, note: string }
export type Message = { id: number, timestamp: number, from_user_id: number, to_user_id: number, text: string }
export type Status = { user_id: number, max_message_id: number }

export const maxTableValueLength = 200
export const maxMessageLength = 250
export const queryLimit = 200
export const email_notification_batch_size = 3

export const noob = "noob"

export const noobRegex = /^noob/i
// export const noobRegex = /^noob[0-9]+$/i

export function timestampToLocalDateTime(unixTimestamp) {
    const milliseconds = unixTimestamp * 1000

    const date = new Date(milliseconds)

    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const dayOfWeek = days[date.getDay()]
    const dayOfMonth = date.getDate()
    const month = months[date.getMonth()]
    const year = date.getFullYear()
    const hours = date.getHours()
    const minutes = date.getMinutes()

    const daySuffix = getDaySuffix(dayOfMonth)

    const formattedDateTime = padZero(hours) + ":" + padZero(minutes) + " " + dayOfWeek + ", " + dayOfMonth + daySuffix + " " + month + " " + year

    return formattedDateTime
}

function getDaySuffix(day) {
    if (day >= 11 && day <= 13) {
        return "th"
    }
    switch (day % 10) {
        case 1: return "st"
        case 2: return "nd"
        case 3: return "rd"
        default: return "th"
    }
}

function padZero(number) {
    return (number < 10 ? "0" : "") + number
}

export function timestampToDate(timestamp) {
    const date = new Date(timestamp * 1000)
    return date.getFullYear() + "-" + padZero(date.getMonth() + 1) + "-" + padZero(date.getDate())
}

export function timestampToTime(timestamp) {
    const date = new Date(timestamp * 1000)
    return padZero(date.getHours()) + ":" + padZero(date.getMinutes())
}
