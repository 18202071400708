[
[187,0,"Art"],
[205,187,"Animation"],
[193,187,"Calligraphy"],
[204,187,"Ceramic art"],
[200,187,"Charcoal drawing"],
[198,187,"Collage art"],
[206,187,"Concept art"],
[191,187,"Digital art"],
[199,187,"Digital painting"],
[189,187,"Drawing"],
[202,187,"Engraving"],
[195,187,"Graphic design"],
[196,187,"Illustration"],
[194,187,"Mixed media"],
[188,187,"Painting"],
[197,187,"Printmaking"],
[190,187,"Sculpture"],
[203,187,"Stencil art"],
[192,187,"Street art"],
[201,187,"Watercolor painting"],

[42,0,"Cooking"],
[46,42,"Asian cuisine"],
[43,42,"Baking"],
[59,42,"Barbecue techniques"],
[49,42,"Cake decorating"],
[50,42,"Cocktail mixing"],
[61,42,"Fermentation"],
[57,42,"Foraging"],
[52,42,"Fusion cuisine"],
[51,42,"Gourmet cooking"],
[44,42,"Grilling"],
[55,42,"Homemade pasta"],
[45,42,"Italian cuisine"],
[47,42,"Mexican cuisine"],
[53,42,"Pastry making"],
[56,42,"Preserving and canning"],
[58,42,"Regional cuisine exploration"],
[60,42,"Vegetable gardening"],
[48,42,"Vegetarian cooking"],
[54,42,"Wine tasting"],

[103,0,"Crafts"],
[120,103,"Basket weaving"],
[111,103,"Candle making"],
[105,103,"Crocheting"],
[110,103,"Embroidery"],
[115,103,"Glassblowing"],
[112,103,"Jewelry making"],
[104,103,"Knitting"],
[116,103,"Leatherworking"],
[121,103,"Metalworking"],
[118,103,"Model building"],
[123,103,"Mosaic art"],
[114,103,"Origami"],
[117,103,"Papermaking"],
[107,103,"Pottery"],
[109,103,"Quilting"],
[113,103,"Scrapbooking"],
[106,103,"Sewing"],
[119,103,"Soap making"],
[122,103,"Weaving"],
[108,103,"Woodworking"],

[62,0,"Fitness"],
[76,62,"Climbing"],
[69,62,"CrossFit"],
[66,62,"Cycling"],
[80,62,"Dance fitness"],
[81,62,"Fitness competitions"],
[82,62,"Functional training"],
[73,62,"Group fitness classes"],
[74,62,"High-intensity interval training (HIIT)"],
[68,62,"Hiking"],
[70,62,"Martial arts"],
[77,62,"Obstacle course racing"],
[71,62,"Pilates"],
[72,62,"Rowing"],
[63,62,"Running"],
[67,62,"Swimming"],
[78,62,"Tai chi"],
[75,62,"Trail running"],
[64,62,"Weightlifting"],
[65,62,"Yoga"],
[79,62,"Zumba"],

[124,0,"Gaming"],
[130,124,"Board games"],
[138,124,"Card games"],
[141,124,"Casual mobile games"],
[126,124,"Console gaming"],
[143,124,"Educational games"],
[139,124,"Escape room games"],
[137,124,"Esports"],
[134,124,"First-person shooter (FPS) games"],
[144,124,"Interactive fiction"],
[135,124,"MMORPGs"],
[127,124,"Mobile gaming"],
[129,124,"Online multiplayer gaming"],
[125,124,"PC gaming"],
[142,124,"Puzzle games"],
[128,124,"Retro gaming"],
[131,124,"Role-playing games (RPGs)"],
[133,124,"Simulation games"],
[132,124,"Strategy games"],
[140,124,"Trivia games"],
[136,124,"Virtual reality gaming"],

[21,0,"Music"],
[29,21,"Classical music appreciation"],
[37,21,"Conducting"],
[26,21,"DJing"],
[24,21,"Drumming"],
[30,21,"Electronic music"],
[41,21,"Folk music"],
[31,21,"Hip hop music"],
[36,21,"Instrument building"],
[33,21,"Music composition"],
[35,21,"Music festivals"],
[40,21,"Music history"],
[38,21,"Music journalism"],
[28,21,"Music production"],
[32,21,"Music theory"],
[39,21,"Music therapy"],
[25,21,"Piano playing"],
[22,21,"Playing guitar"],
[23,21,"Singing"],
[27,21,"Songwriting"],
[34,21,"Sound engineering"],

[1,0,"Photography"],
[14,1,"Aerial photography"],
[19,1,"Architectural photography"],
[8,1,"Astrophotography"],
[11,1,"Black and white photography"],
[207,1,"Bongo"],
[10,1,"Documentary photography"],
[20,1,"Experimental photography"],
[9,1,"Fashion photography"],
[16,1,"Food photography"],
[3,1,"Landscape photography"],
[7,1,"Macro photography"],
[15,1,"Night photography"],
[4,1,"Portrait photography"],
[5,1,"Street photography"],
[13,1,"Time-lapse photography"],
[17,1,"Travel photography"],
[12,1,"Underwater photography"],
[18,1,"Wedding photography"],
[6,1,"Wildlife photography"],

[166,0,"Programming"],
[186,166,"API design"],
[171,166,"Artificial intelligence"],
[180,166,"Augmented reality development"],
[184,166,"Automation scripting"],
[183,166,"Big data analytics"],
[173,166,"Blockchain development"],
[175,166,"Cloud computing"],
[174,166,"Cybersecurity"],
[169,166,"Data science"],
[176,166,"DevOps"],
[177,166,"Embedded systems programming"],
[172,166,"Game development"],
[178,166,"IoT development"],
[170,166,"Machine learning"],
[185,166,"Microservices architecture"],
[168,166,"Mobile app development"],
[182,166,"Natural language processing"],
[179,166,"Quantum computing"],
[181,166,"Virtual reality development"],
[167,166,"Web development"],

[145,0,"Reading"],
[160,145,"Bibliotherapy"],
[151,145,"Biographies"],
[156,145,"Book clubs"],
[159,145,"Book collecting"],
[161,145,"Dystopian fiction"],
[148,145,"Fantasy"],
[146,145,"Fiction"],
[163,145,"Graphic novels"],
[152,145,"Historical fiction"],
[158,145,"Literary criticism"],
[165,145,"Literary magazines"],
[150,145,"Mystery"],
[147,145,"Non-fiction"],
[155,145,"Poetry"],
[149,145,"Science fiction"],
[154,145,"Self-help books"],
[164,145,"Short stories"],
[157,145,"Speed reading"],
[153,145,"Thrillers"],
[162,145,"Young adult fiction"],

[83,0,"Travel"],
[102,83,"Adventure sports travel"],
[86,83,"Adventure travel"],
[101,83,"Archaeological tourism"],
[98,83,"Backcountry skiing"],
[84,83,"Backpacking"],
[90,83,"Budget travel"],
[93,83,"Camping"],
[96,83,"Cruise vacations"],
[97,83,"Cultural festivals"],
[87,83,"Cultural immersion"],
[94,83,"Ecotourism"],
[89,83,"Luxury travel"],
[99,83,"Motorcycle touring"],
[95,83,"Photography tours"],
[88,83,"Road trips"],
[85,83,"Solo travel"],
[92,83,"Sustainable travel"],
[100,83,"Train travel"],
[91,83,"Volunteering abroad"]
]
