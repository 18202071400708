const l = console.log.bind(console)
const $ = document.getElementById.bind(document)
const $$ = document.querySelector.bind(document)

import Qrious from "../node_modules/qrious/dist/qrious.js"
import {
    connectionState, maxMessageLength, noobRegex, timestampToDate, timestampToTime,
    Data, User, Connection, Message,
    Status
} from "./universal.ts"

import { library, icon } from '../node_modules/@fortawesome/fontawesome-svg-core/index.js'
import { faCircleArrowLeft, faCircleNodes, faEnvelope, faHouse, faQrcode, faUser, faUsers } from "../node_modules/@fortawesome/free-solid-svg-icons"

library.add(faCircleArrowLeft, faCircleNodes, faEnvelope, faHouse, faQrcode, faUser, faUsers)

l(">>>", faCircleNodes.toString())

const ico = {
    faCircleArrowLeft: icon({ prefix: "fas", iconName: "circle-arrow-left" }).html[0],
    faCircleNodes: icon({ prefix: "fas", iconName: "circle-nodes" }).html[0],
    faEnvelope: icon({ prefix: "fas", iconName: "envelope" }).html[0],
    faHouse: icon({ prefix: "fas", iconName: "house" }).html[0],
    faQrcode: icon({ prefix: "fas", iconName: "qrcode" }).html[0],
    faUser: icon({ prefix: "fas", iconName: "user" }).html[0],
    faUsers: icon({ prefix: "fas", iconName: "users" }).html[0],
}
import global_interests from "./data/interests.json"

type InterestTuple = [id: number, category: number | null, name: string]

enum MenuLocation {
    dropdown = 1,
    top = 2,
    none = 3,
    both = 4,
}

let main, nav, footer
let data: Data

const global: {
    messages: Message[],
    // max_connection_id: number,
    seen_message_id: number,
    status: Status,
} = {
    messages: [],
    // max_connection_id: 0,
    seen_message_id: 0,
    status: {} as Status,
}

const page_handlers: [title: string, icon: string, hash: string, menuLocation: MenuLocation, (arg: string) => void][] = [
    ["QR", ico.faQrcode, "#qr", MenuLocation.top, qr_page],
    ["Home", ico.faHouse, "#home", MenuLocation.top, home_page],
    ["Connections", ico.faCircleNodes, "#connections", MenuLocation.top, connections_page],
    ["Users", ico.faUsers, "#users", MenuLocation.top, users_page],
    ["Message", ico.faEnvelope, "#message", MenuLocation.top, message_page],
    ["User", ico.faUser, "#user", MenuLocation.top, user_page],

    ["Profile", "Profile", "#profile", MenuLocation.none, profile_page],
    ["Settings", "Settings", "#settings", MenuLocation.none, settings_page,],
    ["All Messages", ico.faEnvelope, "#all-messages", MenuLocation.none, all_message_page],

    // ["Set Username", "Set Username", "#setusername", MenuLocation.none, setusername_page],

    ["Bio", "Bio", "#bio", MenuLocation.dropdown, bio_page],
    ["Interests", "Interests", "#interests", MenuLocation.dropdown, interests_page],
    ["Email Addresses", "Emails", "#emails", MenuLocation.dropdown, emails_page],
    ["Logout", "Logout", "/api/logout", MenuLocation.dropdown, () => { }],
]

window.onload = async () => {
    console.log("---------------------------------------")
    window.addEventListener('hashchange', function () {
        // route()
    })

    window.addEventListener("popstate", function (event) {
        if (event.state) {
            l("popstate with state", event.state)
        } else {
            l("popstate no state", event)
        }
        route()
    })

    // window.onclick = function (event) {
    //     l("Window click", event.target)
    //     const dropdowns = document.querySelectorAll(".dropdown-menu");
    //     const username = $("username")
    //     if (event.target == username) {
    //         for (const dropdown of dropdowns) {
    //             dropdown.classList.toggle('show');
    //         }
    //     }
    //     else {
    //         for (const dropdown of dropdowns) {
    //             dropdown.classList.remove('show');
    //         }
    //     }
    // }

    main = $$("main")
    nav = $$("nav")
    footer = $$("footer")

    initialiseTopMenu()

    await load_data()
    await load_status()
    checkStatus()

    // Set top right username
    // const div = document.getElementById("username")
    if (data && data.user && data.user) {
        if (data.user.name) {
            // div.textContent = data.user.name
            document.title = data.user.name
        }
        //     else {
        //         div.textContent = "No name"
        //     }
    }

    // const tab = $$(`[data="#connections"]`)
    // tab.classList.add("fa-beat")

    setInterval(async () => { await poll_server() }, 5 * 60 * 1000)

    route()
}

async function poll_server() {
    // l(location.hash)
    await load_status()
    checkStatus()
}

async function load_data() {
    const url = "/api/data"
    try {
        const response = await fetch(url, { credentials: "include" })
        l(response)
        if (response.ok) {
            data = await response.json()
        }
        else if (response.status == 401) {
            throw Error("Unauthorised")
        }
        else {
            throw Error("Unable to fetch data")
        }

        if (data == null || data.users == null) throw Error("Broken response")

        // put the ids back into the objects.
        // they were removed for transmission
        for (const id of Object.keys(data.users)) {
            data.users[id].id = id
        }

        data["interests"] = {}
        for (const interest of global_interests as InterestTuple[]) {
            const [id, , name] = interest
            data.interests[id] = name
        }

        l("development:", data.development)

        for (const connection of data.connections) {
            if (connection.state == connectionState.new && connection.to_user_id == data.user.id) {
                setIndicator("#connections")
                break
            }
        }
    }
    catch (error) {
        console.error(error)
        const div = document.querySelector("footer")
        if (div) {
            div.textContent = `Unable to fetch data from server: ${error.message}`
            div.classList.add("error")
        }
    }
}

async function load_messages() {
    let messagesResponse
    l("leb", global.messages.length, "seen", global.seen_message_id, "max", global.status.max_message_id)
    if (global.messages.length > 0) {
        if (global.status.max_message_id <= global.seen_message_id) return
        messagesResponse = await fetch(`/api/getmessages/${global.seen_message_id}`)
    }
    else {
        messagesResponse = await fetch(`/api/getmessages/0`)
    }
    if (!messagesResponse.ok) throw Error("Can't fetch data")
    global.messages = await messagesResponse.json()
    global.seen_message_id = global.messages.reduce((maxValue, obj) => Math.max(maxValue, obj.id), 0);
}

async function load_status() {
    let statusResponse: Response
    l("max message id", global.status.max_message_id)
    if (global.status.max_message_id) {
        statusResponse = await fetch(`/api/status?max_message_id=${global.status.max_message_id}`)
    }
    else {
        statusResponse = await fetch("/api/status")
    }
    global.status = await statusResponse.json() as Status
    l(global.status)
}

async function checkStatus() {
    // const statusResponse = await fetch("/api/status")
    // const status = await statusResponse.json() as Status

    if (global.status.max_message_id > data.user.seen_message_id) {
        l("global max message", global.status.max_message_id, "data seen", data.user.seen_message_id)
        const tab = $$(`[data="#message"]`)
        tab.classList.add("fa-beat")
        return true
    }
    return false
}

function clearIndicator(tabHash) {
    const tab = $$(`[data="${tabHash}"]`)
    tab.classList.remove("fa-beat")
}

function setIndicator(tabHash) {
    const tab = $$(`[data="${tabHash}"]`)
    tab.classList.add("fa-beat")
}

async function putJSON(url, options = {}): Promise<object | null> {
    return fetchJSON("PUT", url, options)
}

async function fetchJSON(method: string, url: string, options: RequestInit = {}): Promise<object | null> {
    options.method = method
    options.credentials = "include"
    const response = await fetch(url, options)
    if (response.ok) {
        return response.json()
    }
    return null
}

async function route() {
    const hash = location.hash
    l("route", hash)

    if (data == null || data.user == null) {
        main.replaceChildren()
        addH1(main, "Redirecting")
        setTimeout(() => {
            location.href = "/login"
        }, (2000));
        return
    }

    await load_status()
    checkStatus()

    const matches = hash.match(/(#[-a-z]+)[/]?(.*)/i)
    if (matches) {
        l("Route matches", matches)
        let [, page, argument] = matches
        page = page.toLowerCase()
        argument = argument.toLowerCase()
        highlightActivePage(page)
        for (const [, , pageHash, , handler] of page_handlers) {
            if (page == pageHash) {
                main.replaceChildren()
                nav.replaceChildren()
                footer.replaceChildren()
                handler.call(this, argument)
                return
            }
        }
        home_page()
    }
    else {
        // when we set the hash that will trigger popstate to call us again
        const defaultPage = "connections"
        window.location.hash = `#${defaultPage}`
    }
}

function initialiseTopMenu() {
    const menuBar = $$(".top-menu-bar")
    // const dropdownMenu = $$(".dropdown-menu")
    for (const [title, icon, linkString, location] of page_handlers) {
        if (location == MenuLocation.top || location == MenuLocation.both) {
            const linkElement = addLink(menuBar, linkString)
            const item = addHTMLElement(linkElement, "div", "top-menu", icon)
            item.setAttribute("data", linkString)
            item.setAttribute("title", title)
        }
    }
}

function highlightActivePage(page: string) {
    const tabs = document.querySelectorAll(".top-menu")
    for (const tab of tabs) {
        if (tab.getAttribute("data") == page) {
            tab.classList.add("active")
        }
        else {
            tab.classList.remove("active")
        }
    }
}

///////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////// QR
///////////////////////////////////////////////////////////////////////////////

async function qr_page() {
    const qrLink = `${location.origin}/qr/${data.user.name}`
    main.replaceChildren()
    const container = addDiv(main, "canvas-container")
    if (data.user.name && !data.user.name.match(noobRegex)) {
        const canvas = addElement(container, "canvas")
        canvas.setAttribute("id", "qr")
        const div = addDiv(container, "qr-link")
        addHref(div, qrLink, qrLink)


        // x @ts-expect-error "This expression is not constructable"
        new Qrious({
            element: $("qr"),
            value: qrLink,
            size: 300,
            level: "H"
        });
    }
    else {
        addHref(container, "/choose-username", "You need to choose a username. Click Here.")

    }
}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////// HOME PAGE
///////////////////////////////////////////////////////////////////////////////

async function home_page() {
    l("Home page")
    main.replaceChildren()
    addElement(main, "h1", null, "Home")
    addLink(main, `/connect/${data.user.name}`, "Shareable connection link")
    addDiv(main)
    addLink(main, `/login`, "Login")
    addDiv(main)
    addLink(main, `/connect-done`, "Connect Done")
    addDiv(main)
    addLink(main, `/login-done`, "Login Done")
    if (data.user.name == null || data.user.name.match(noobRegex)) addSetUserName(main)
    addElement(main, "pre", null, JSON.stringify(data.user, null, 2))
}

function addSetUserName(parent: Element) {
    l(parent)
}

///////////////////////////////////////////////////////////////////////////////


function connections_page(argument: string | undefined) {
    const old = argument == "old"
    const page = addDiv(main, "page-column")
    clearIndicator("#connections")
    // putJSON(`/api/update/user/seen_connection_id/${global.max_connection_id}`)

    let inboundDiv, outboundDiv, connectionsDiv
    const inboundContainer = addDiv(page, "inbound connections-container")
    const connectionsContainer = addDiv(page, "connected connections-container")
    const outboundContainer = addDiv(page, "outbound connections-container")

    let firstInbound = true
    let firstOutbound = true
    let firstConnection = true
    let connectionShown = false
    let otherConnections = false

    for (const connection of data.connections.sort(connectionsSorter)) {
        let div: Element | undefined
        if (old) {
            if (connection.state == connectionState.connected || connection.state == connectionState.new) {
                otherConnections = true
                continue
            }
        }
        else {
            if (connection.state != connectionState.connected && connection.state != connectionState.new) {
                otherConnections = true
                continue
            }
        }
        connectionShown = true
        if (connection.from_timestamp && connection.to_timestamp) {
            if (firstConnection) {
                firstConnection = false
                addH1(connectionsContainer, old ? "Broken Connections" : "Connections", "top-margin-0")
                connectionsDiv = addDiv(connectionsContainer, "connected connections")
            }
            div = connectionsDiv
        }
        else if (connection.to_user_id == data.user.id) {
            if (firstInbound) {
                firstInbound = false
                addH1(inboundContainer, old ? "Requests Discarded" : "Requests Received", "top-margin-0")
                inboundDiv = addDiv(inboundContainer, "inbound connections")
            }
            div = inboundDiv
        }
        else {
            if (firstOutbound) {
                firstOutbound = false
                addH1(outboundContainer, old ? "Requests Cancelled" : "Requests Sent")
                outboundDiv = addDiv(outboundContainer, "outbound connections")
            }
            div = outboundDiv
        }
        if (div == null) {
            console.error(data.user.id, connection)
            throw Error("Bad connection")
        }
        const other_user_id = connection.from_user_id == data.user.id ? connection.to_user_id : connection.from_user_id
        const panel = addDiv(div, "user-list-panel")
        const userPanel = addUserPanel(panel, data.users[other_user_id])
        addConnectionNotePanel(userPanel, connection)
        addUserButtonPanel(userPanel, data.users[other_user_id])
    }

    if (old) {
        if (otherConnections) addButtonWithoutClass(addHref(page, "#connections"), "Normal connections")
    }
    else {

        if (connectionShown) {
            addParagraph(page, "Click on username to manage connections")
        }
        else {
            addParagraph(page, "You have no connections")
        }

        if (otherConnections) addButtonWithoutClass(addHref(page, "#connections/old"), "Old connections")
    }
}

function connectionsSorter(a, b) {
    // Handling cases where either from_timestamp or to_timestamp is null
    if (a.from_timestamp === null && b.from_timestamp !== null) {
        return 1;
    }
    if (a.from_timestamp !== null && b.from_timestamp === null) {
        return -1;
    }
    if (a.to_timestamp === null && b.to_timestamp !== null) {
        return 1;
    }
    if (a.to_timestamp !== null && b.to_timestamp === null) {
        return -1;
    }

    // Sorting based on the newest timestamp
    return Math.max(b.from_timestamp, b.to_timestamp) - Math.max(a.from_timestamp, a.to_timestamp);
}

async function handleConnectionClick(event: MouseEvent) {
    event.stopPropagation()
    l("handle click")
    const target = event.target as HTMLButtonElement
    if (!target) return false
    const attrib = target.getAttribute("data")
    if (!attrib) return false
    const [from_user_id, to_user_id, newState] = JSON.parse(attrib)
    l(from_user_id, to_user_id, newState)
    const connection = findConnection(from_user_id, to_user_id)
    for (const sibling of getSiblings(target)) {
        sibling.classList.remove("set")
    }
    target.classList.add("set")
    if (connection) {
        const result = await putJSON(`/api/setconnection/${connection.from_user_id}/${connection.to_user_id}/${newState}`) as Connection
        Object.assign(connection, result)
    }
    else {
        const result = await putJSON(`/api/setconnection/${from_user_id}/${to_user_id}/${newState}`) as Connection
        if (result) data.connections.push(result)
    }
    l("updated connection", connection)
    l(data.connections)
    route()
}

function getSiblings(element: HTMLElement | null): HTMLElement[] {
    if (!element) return []
    const parent = element.parentNode;
    if (!parent) return []
    return Array.from(parent.childNodes).filter(node => {
        return node.nodeType === Node.ELEMENT_NODE && node !== element;
    }) as HTMLElement[]
}

function findConnection(a_user_id: number, b_user_id: number) {
    // l(data.connections)
    for (const connection of data.connections) {
        if (connection.from_user_id == a_user_id && connection.to_user_id == b_user_id
            || connection.from_user_id == b_user_id && connection.to_user_id == a_user_id) return connection
    }
    return null
}

function addBigUserPanel(panel: Element, user: User, manageConnection = false) {
    panel = addUserPanel(panel, user)
    const connection = findConnection(data.user.id, user.id)
    if (connection) addConnectionNotePanel(panel, connection)
    addUserButtonPanel(panel, user, manageConnection)
}

function addUserPanel(panel: Element, user: User) {
    const userPanel = addDiv(panel, "user-panel")
    const userTopBar = addDiv(userPanel, "user-top-bar")
    const userName = addDiv(userTopBar, "user-name")
    if (user.name) {
        addHref(userName, `#profile/${user.name} `, user.name)
        if (data.development) addHref(userTopBar, `/api/switch/${user.id}`, "switch")
    }
    else {
        addText(userName, "No name yet")
    }
    addDiv(userPanel, "user-bio", user.bio)
    addDiv(userPanel, "user-interests", user.interests.map(x => data.interests[x]).join(", "))
    return userPanel
}

function addConnectionNotePanel(panel: Element, connection: null | Connection) {
    if (connection && connection.note) addDiv(panel, "connection-note").textContent = connection.note
}

function addUserButtonPanel(panel: Element, other_user: User, addBreak = false) {
    const buttonBar = addDiv(panel, "button-bar")
    const connection = findConnection(data.user.id, other_user.id)
    if (!connection) {
        if (data.user.id != other_user.id) {
            addButton(buttonBar, "connect", "Connect", handleConnectClick).setAttribute("data", JSON.stringify([data.user.id, other_user.id, connectionState.new]))
            // addButton(buttonBar, "request", "Request Connection", handleConnectionClick).setAttribute("data", JSON.stringify([data.user.id, other_user.id, connectionState.new]))
        }
    }
    else if (connection.from_timestamp && connection.to_timestamp) {
        // an existing connection
        if (connection.state == connectionState.broken) {
            addButton(buttonBar, "restore", "Restore Connection", handleConnectionClick).setAttribute("data", JSON.stringify([data.user.id, other_user.id, connectionState.connected]))
        }
        else if (addBreak) {
            addButton(buttonBar, "break", "Break Connection", handleConnectionClick).setAttribute("data", JSON.stringify([data.user.id, other_user.id, connectionState.broken]))
        }
        // const otherUserName=]
        addButton(addHref(buttonBar, `#message/${other_user.name}`), null, "Message")
    } else if (connection.from_user_id == data.user.id) {
        // outbound request
        if (connection.state == connectionState.new) {
            addButton(buttonBar, "cancel", "Cancel Request", handleConnectionClick).setAttribute("data", JSON.stringify([data.user.id, other_user.id, connectionState.cancelled]))
        }
        else {
            addButton(buttonBar, "revive", "Revive Request", handleConnectionClick).setAttribute("data", JSON.stringify([data.user.id, other_user.id, connectionState.new]))
        }
    }
    else {
        // inbound request
        if (connection.state == connectionState.new) addButton(buttonBar, "discard", "Discard Request", handleConnectionClick).setAttribute("data", JSON.stringify([data.user.id, other_user.id, connectionState.discarded]))
        addButton(buttonBar, "accept", "Accept Request", handleConnectionClick).setAttribute("data", JSON.stringify([data.user.id, other_user.id, connectionState.connected]))
    }
}

function handleConnectClick(event) {
    if (!event.target) return
    const [user_id, other_user_id, state] = JSON.parse(event.target.getAttribute("data"))
    l({ user_id, other_user_id, state })
    const popup = $$(".modal.connect")
    const requestButton = $$(".modal.connect .request")
    requestButton.addEventListener("click", closeConnectionPopup)
    requestButton.addEventListener("click", handleConnectionClick)
    requestButton.setAttribute("data", JSON.stringify([user_id, other_user_id, connectionState.new]))
    $$(".modal.connect form").addEventListener("submit", (event) => { event.preventDefault() })
    $$(".modal.connect .cancel").addEventListener("click", closeConnectionPopup)
    $$(".modal.connect .label").textContent = `Connect with ${data.users[other_user_id].name}`
    popup.classList.add("active")
}

function closeConnectionPopup() {
    $$(".modal.connect").classList.remove("active")
}

///////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////// ELEMENTS
///////////////////////////////////////////////////////////////////////////////


function addLink(parent: Element, link, html: string | null = null) {
    const element = addElement(parent, "a", null, html) as HTMLLinkElement
    element.href = link
    return element
}

function addForm(parent: Element, className: string | null = null, html: string | null = null) {
    return addElement(parent, "form", className, html) as HTMLFormElement
}

function addElement(parent: Element, elementType: string, className: string | null = null, text: string | null = null) {
    const element = document.createElement(elementType)
    if (text) element.textContent = text
    if (className) element.classList.add(...(className.split(" ")))
    parent.appendChild(element)
    return element
}

function addHTMLElement(parent: Element, elementType: string, className: string | null = null, html: string | null = null) {
    const element = document.createElement(elementType)
    if (html) element.innerHTML = html
    if (className) element.classList.add(className)
    parent.appendChild(element)
    return element
}

type ClickEventHandler = (event: MouseEvent) => void

function addButtonWithoutClass(parent: Element, text: string | null = null, handler: ClickEventHandler | null = null) {
    const element = addElement(parent, "button", null, text)
    if (handler) element.addEventListener("click", handler)
    return element
}

function addButton(parent: Element, className: string | null = null, text: string | null = null, handler: ClickEventHandler | null = null) {
    const element = addElement(parent, "button", className, text)
    if (handler) element.addEventListener("click", handler)
    return element
}

function addDiv(parent: Element, className: string | null = null, text: string | null = null) {
    const element = addElement(parent, "div", className, text)
    parent.appendChild(element)
    return element
}

function addH1(parent: Element, text: string | null = null, className: string | null = null) {
    const element = document.createElement("h1")
    if (text) element.textContent = text
    if (className) element.classList.add(className)
    parent.appendChild(element)
    return element
}


export function addH2(parent: Element, text: string | null = null) {
    const element = document.createElement("h2")
    if (text) element.textContent = text
    parent.appendChild(element)
    return element
}

function addHref(parent: Element, link: string, text: string | null = null) {
    const element = document.createElement("a")
    if (text) element.textContent = text
    if (link) element.href = link
    parent.appendChild(element)
    return element
}

function addParagraph(parent: Element, text: string | null = null) {
    const element = addElement(parent, "p", null, text)
    if (text) element.textContent = text
    parent.appendChild(element)
    return element
}

function addText(parent: Element, text: string | null) {
    if (text) {
        const element = document.createTextNode(text)
        parent.appendChild(element)
    }
}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////// USERS
///////////////////////////////////////////////////////////////////////////////

function users_page() {
    main.replaceChildren()
    addParagraph(main, "")
    for (const user of Object.values(data.users)) {
        addBigUserPanel(main, user)
    }
}

///////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////// PROFILE
///////////////////////////////////////////////////////////////////////////////

function profile_page(username: string | undefined) {
    main.replaceChildren()
    addParagraph(main, "")
    l({ username })
    if (username) {
        for (const user of Object.values(data.users)) {
            // l("username", username, "user.name", user.name, username.localeCompare(user.name, undefined, { sensitivity: 'base' }))
            if (username.localeCompare(user.name, undefined, { sensitivity: 'base' }) == 0) {
                l("match")
                addBigUserPanel(main, user, true)
                break
            }
        }
    }
    else {
        console.log(data.user)
        addBigUserPanel(main, data.user)
    }
}

function findUser(username: string): User | null {
    for (const user of Object.values(data.users)) {
        if (username.localeCompare(user.name, undefined, { sensitivity: 'base' }) == 0) {
            return user
        }
    }
    return null
}

///////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////// SETTINGS
///////////////////////////////////////////////////////////////////////////////

function settings_page() {
    main.replaceChildren()
    addH1(main, "Settings")
    addText(main, JSON.stringify(data.user))
}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////// INTERESTS
///////////////////////////////////////////////////////////////////////////////

function interests_page() {
    main.replaceChildren()
    const page = addDiv(main, "interests-page")
    addH1(page, "Interests")
    addParagraph(page, "You can tick categories as well as specific interests.")
    const panel = addDiv(page, "interests-list")

    for (const interest of global_interests as InterestTuple[]) {
        // l(interest)
        const [interest_id, category, name] = interest
        const div = addDiv(panel)
        const tick = addElement(div, "input")
        tick.setAttribute("type", "checkbox")
        tick.setAttribute("id", String(interest_id))
        tick.setAttribute("value", String(interest_id))
        tick.addEventListener("change", handleInterestChange)
        if (data.users[data.user.id].interests.includes(interest_id)) tick.setAttribute("checked", "1")
        const label = addElement(panel, "label", !category ? "category" : "normal", name)
        label.setAttribute("for", String(interest_id))
    }

    async function handleInterestChange(event) {
        l(event.target)
        const interest_id = event.target.getAttribute("id")
        const state = (event.target.checked) ? 1 : 0
        await putJSON(`/api/setinterest/${interest_id}/${state}`)
        const my_interests = data.users[data.user.id].interests
        // l("bef", my_interests)
        if (state) {
            if (!my_interests.includes(interest_id)) my_interests.push(Number(interest_id))
        }
        else {
            data.users[data.user.id].interests = my_interests.filter(x => x != interest_id)
        }
        // l("aft", data.users[data.user.id].interests)
    }
}

///////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////// ALL MESSAGE
///////////////////////////////////////////////////////////////////////////////

async function all_message_page(username: string) {
    main.replaceChildren()
    addParagraph(main, "")
    // const div = main
    l({ username })
    if (username) {
        for (const user of Object.values(data.users)) {
            // l("username", username, "user.name", user.name, username.localeCompare(user.name, undefined, { sensitivity: 'base' }))
            if (username.localeCompare(user.name, undefined, { sensitivity: 'base' }) == 0) {
                l("match")
                addBigUserPanel(main, user)
                break
            }
        }
    }
    else {
        await load_messages()
        const list = addDiv(main, "all-message-list")

        let startTime = performance.now()
        const sortedMessages = global.messages.sort((a, b) => b.timestamp - a.timestamp)
        let endTime = performance.now()
        l("Time:", endTime - startTime, "Length:", sortedMessages.length)
        startTime = performance.now()
        for (const message of sortedMessages) {
            const from_user = data.users[message.from_user_id]
            const to_user = data.users[message.to_user_id]
            const from_user_name = from_user && from_user.name ? from_user.name : "Unknown"
            const to_user_name = to_user && to_user.name ? to_user.name : "Unknown"
            const other_user_name = message.from_user_id == data.user.id ? to_user_name : from_user_name
            // l({ from: message.from_user_id, from_user, to: message.to_user_id, to_user })
            // const div = addDiv(main, "message")
            const div = list
            const timestamp = addDiv(div, "timestamp")
            addDiv(timestamp, "date", timestampToDate(message.timestamp))
            addDiv(timestamp, "time", timestampToTime(message.timestamp))
            addHref(div, `#message/${other_user_name}`, (message.from_user_id == data.user.id ? "➤" : "") + other_user_name).classList.add("other-user-name")
            addDiv(div, "text", message.text)
            await new Promise(resolve => setTimeout(resolve, 0))
        }
        endTime = performance.now()
        l("Draw Time:", endTime - startTime)
    }
}

///////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////// MESSAGE
///////////////////////////////////////////////////////////////////////////////

async function message_page(username: string) {
    await load_messages()
    if (username) {
        const other_user = findUser(username)
        if (!other_user) return
        const chat_header = addDiv(nav, "chat-header")
        addHTMLElement(chat_header, "div", "", ico.faCircleArrowLeft)
        addDiv(chat_header, "", username)
        chat_header.addEventListener("click", () => { history.back() })
        const chat_list = addDiv(main, "chat-list")
        let lastDiv
        for (const message of global.messages.sort((a, b) => a.timestamp - b.timestamp)) {
            if (message.from_user_id == other_user.id || message.to_user_id == other_user.id) {
                lastDiv = addMessage(chat_list, message)
            }
        }
        const input = addDiv(footer, "chat-input")
        const ta = addElement(input, "textarea") as HTMLTextAreaElement
        // ta.setAttribute("rows", "2")
        ta.setAttribute("maxlength", String(maxMessageLength))
        ta.addEventListener("keypress", handleKey)
        addButton(input, "send-button", "Send", handleSendButton)
        // addDiv(input, "send-button", "Send")
        lastDiv.scrollIntoView(true)

        async function handleKey(event) {
            if (event.code == "Enter" && event.ctrlKey) handleSendButton(event)
        }

        async function handleSendButton(event) {
            event.stopPropagation()
            const text = ta.value
            if (!text || !other_user) return
            const message = await putJSON(`/api/sendmessage/${other_user.id}/${encodeURIComponent(text)}`) as Message
            addMessage(chat_list, message as Message).scrollIntoView()
            ta.value = ""
        }
    }
    else {
        clearIndicator("#message")
        data.user.seen_message_id = global.seen_message_id
        putJSON(`/api/update/user/seen_message_id/${global.seen_message_id}`)
        const list = addDiv(main, "message-list")
        let startTime = performance.now()
        const sortedMessages = global.messages.sort((a, b) => b.timestamp - a.timestamp)
        let endTime = performance.now()
        l("Time:", endTime - startTime, "Length:", sortedMessages.length)
        startTime = performance.now()
        const usersDone = {}
        for (const message of sortedMessages) {
            const from_user = data.users[message.from_user_id]
            const to_user = data.users[message.to_user_id]
            const from_user_name = from_user && from_user.name ? from_user.name : "Unknown"
            const to_user_name = to_user && to_user.name ? to_user.name : "Unknown"
            const other_user_name = message.from_user_id == data.user.id ? to_user_name : from_user_name
            if (usersDone[other_user_name]) continue
            usersDone[other_user_name] = true
            // l({ from: message.from_user_id, from_user, to: message.to_user_id, to_user })
            // const div = addDiv(main, "message")
            // const messageDiv = addDiv(list, "message")
            const messageDiv = addHref(list, `#message/${other_user_name}`,)
            messageDiv.classList.add("message")
            const header = addDiv(messageDiv, "message-header")
            const timestamp = header//addDiv(header, "timestamp")
            addDiv(timestamp, "date", timestampToDate(message.timestamp))
            addDiv(timestamp, "time", timestampToTime(message.timestamp))
            addDiv(header, "other-user-name", (message.from_user_id == data.user.id ? "➜" : "") + other_user_name)
            addDiv(messageDiv, "text", message.text)
            await new Promise(resolve => setTimeout(resolve, 0))
        }
        endTime = performance.now()
        l("Draw Time:", endTime - startTime)
    }
    return

    function addMessage(div: HTMLElement, message: Message) {
        return addElement(div, "div", message.from_user_id == data.user.id ? "chat-out" : "chat-in", message.text)
    }
}

///////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////// USER
///////////////////////////////////////////////////////////////////////////////

async function user_page() {
    const link = `${location.origin}/connect/${data.user.name}`
    const page = addDiv(main, "page-column")
    addH1(page, data.user.name)
    const list = addDiv(page, "menu-list")
    // list.classList.add("medium")
    if (data.user.name.match(noobRegex)) {
        const link = addLink(list, "/choose-username", "Choose Username")
        link.classList.add("panel")
    }
    for (const [title, , linkString, location] of page_handlers) {
        if (location == MenuLocation.dropdown || location == MenuLocation.both) {
            const link = addLink(list, linkString, title)
            link.classList.add("panel")
        }
    }
    addButton(list, null, "Copy shareable connection link", () => { copyToClipboard(link); message.textContent = "Copied" })
    const message = addDiv(list)
    // addLink(list, "/api/logout", "Logout")
}

function copyToClipboard(text) {
    const textarea = document.createElement("textarea")
    textarea.value = text
    textarea.style.position = "fixed"
    textarea.style.opacity = "0"
    document.body.appendChild(textarea)
    textarea.select()
    try {
        document.execCommand("copy")
        console.log("Text copied to clipboard")
    } catch (err) {
        console.error("Unable to copy text to clipboard", err)
    }
    document.body.removeChild(textarea)
}

///////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////// BIO
///////////////////////////////////////////////////////////////////////////////

async function bio_page() {
    const page = addDiv(main, "page-column")
    addH1(page, "Bio")//.classList.add("center")
    const form = addForm(page, "form-column")
    const message = addDiv(page)

    form.addEventListener("submit", async (event) => {
        event.preventDefault()
        const target = event.target as HTMLFormElement
        if (!target) return
        const field = target.elements[0] as HTMLTextAreaElement
        await putJSON(`/api/update/user/bio/${encodeURIComponent(field.value)}`)
        data.user.bio = field.value
        message.textContent = "Saved"
    })
    form.addEventListener("input", () => {
        message.textContent = "Changed"
    })
    form.addEventListener("keypress", (event) => {
        if (event.code == "Enter" && event.ctrlKey) form.dispatchEvent(new Event("submit"))
    })

    const ta = addElement(form, "textarea") as HTMLTextAreaElement
    ta.setAttribute("name", "bio")
    ta.value = data.user.bio
    ta.classList.add("bio")
    ta.rows = 10
    addButton(form, null, "Save")
}

///////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////// EMAILS
///////////////////////////////////////////////////////////////////////////////

async function emails_page() {
    const page = addDiv(main, "page-column")
    addH1(page, "Email Addresses")
    addParagraph(page, "Any of the addresses below can be used to login")
    const list = addDiv(page, "center")
    for (const email of data.user.emails) {
        addH2(list, email)
    }
    const form = addForm(page, "form-column")
    form.addEventListener("submit", async (event) => {
        event.preventDefault()
        const target = event.target as HTMLFormElement
        if (!target) return
        const field = target.elements[0] as HTMLTextAreaElement
        await putJSON(`/api/addemail/${encodeURIComponent(field.value)}`)
        message.textContent = `Sending verification email to ${field.value}`
        field.value = ""
    })
    form.addEventListener("input", () => {
        message.textContent = ""
    })
    form.addEventListener("keypress", (event) => {
        if (event.code == "Enter" && event.ctrlKey) form.dispatchEvent(new Event("submit"))
    })
    addElement(form, "label", null, "Add a new email address below").setAttribute("for", "email")
    const emailInput = addElement(form, "input")
    emailInput.setAttribute("id", "email")
    emailInput.setAttribute("type", "email")
    emailInput.setAttribute("autocomplete", "email")
    addButton(form, null, "Add email address").setAttribute("type", "submit")
    const message = addDiv(form)
}
